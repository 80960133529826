import request from '@/utils/request'

// =============================
// TODO: 数据配置管理国度
// =============================
/**
 * [配置分类]列表
 * @param params
 * @returns {*}
 */
export function getClassifyListApi (params) {
  return request({
    url: 'system/config_class',
    method: 'get',
    params
  })
}

/**
 * [配置分类]新增表单
 * @returns {*}
 */
export function createClassConfigApi () {
  return request({
    url: 'system/config_class/create',
    method: 'get'
  })
}

/**
 * [配置分类]编辑表单
 * @param id
 * @returns {*}
 */
export function editClassifyApi (id) {
  return request({
    url: `system/config_class/${id}/edit`,
    method: 'get'
  })
}

/**
 * [配置]列表
 * @param params
 * @returns {*}
 */
export function getConfigListApi (params) {
  return request({
    url: 'system/config',
    method: 'get',
    params
  })
}

/**
 * [配置]新增表单
 * @param params
 * @returns {*}
 */
export function createConfigApi (params) {
  return request({
    url: 'system/config/create',
    method: 'get',
    params
  })
}

/**
 * [配置]编辑表单
 * @param id
 * @returns {*}
 */
export function configEditApi (id) {
  return request({
    url: `/system/config/${id}/edit`,
    method: 'get'
  })
}

/**
 * 设置 系统设置 应用设置头部
 * @param params
 * @returns {*}
 */
export function getGroupHeaderListApi (params) {
  return request({
    url: 'setting/config/header_basics',
    method: 'get',
    params
  })
}

/**
 * 设置 系统设置 应用设置 编辑表单
 * @param params
 * @param url
 * @returns {*}
 */
export function getFromDataApi (params, url) {
  return request({
    url: url,
    method: 'get',
    params
  })
}

// =============================
// TODO: 云存储配置管理国度
// =============================
/**
 * 储存配置-获取云储存配置头
 * @returns {*}
 */
export function getStorageConfigApi () {
  return request({
    url: 'setting/storage/config',
    method: 'get'
  })
}

/**
 * 储存配置-获取云存储列表
 * @param params
 * @returns {*}
 */
export function getStorageListApi (params) {
  return request({
    url: 'setting/storage/list',
    method: 'get',
    params
  })
}

/**
 * 储存配置-获取云存储配置
 * @param data
 * @returns {*}
 */
export function getStorageSwitchApi (data) {
  return request({
    url: 'setting/storage/config',
    method: 'post',
    data
  })
}

/**
 * 储存配置-获取缩略图
 * @returns {*}
 */
export function getPositionInfoApi () {
  return request({
    url: 'setting/config/config_list/31',
    method: 'get'
  })
}

/**
 * 储存配置-获取云储存配置表单
 * @param type
 * @returns {*}
 */
export function getEditConfigApi (type) {
  return request({
    url: `setting/storage/form/${type}`,
    method: 'get'
  })
}

/**
 * 储存配置-获取云存储创建表单
 * @param type
 * @returns {*}
 */
export function getCreateFromConfigApi (type) {
  return request({
    url: `setting/storage/create/${type}`,
    method: 'get'
  })
}

/**
 * 储存配置-同步空间
 * @param type
 * @returns {*}
 */
export function storageSynchApi (type) {
  return request({
    url: `setting/storage/synch/${type}`,
    method: 'put'
  })
}

/**
 * 储存配置-修改空间域名
 * @param id
 * @returns {*}
 */
export function setEditUrlStorageApi (id) {
  return request({
    url: `setting/storage/domain/${id}`,
    method: 'get'
  })
}

/**
 * 储存配置-修改状态
 * @param id
 * @returns {*}
 */
export function setStorageStatusApi (id) {
  return request({
    url: `setting/storage/status/${id}`,
    method: 'put'
  })
}

/**
 * 储存配置-保存缩略图
 * @param data
 * @returns {*}
 */
export function positionPostApi (data) {
  return request({
    url: 'setting/config/save_basics',
    method: 'post',
    data
  })
}

/**
 * 储存配置切换
 * @param type
 * @returns {*}
 */
export function setStorageSaveTypeApi (type) {
  return request({
    url: `setting/storage/save_type/${type}`,
    method: 'get'
  })
}

// =============================
// TODO: 消息通知管理国度
// =============================
/**
 * 获取消息管理列表数据
 * @param type
 * @returns {AxiosPromise}
 */
export function getNotificationListApi (type) {
  return request({
    url: `setting/notification/index?type=${type}`,
    method: 'get'
  })
}

/**
 * 修改模板信息状态
 * @param type
 * @param status
 * @param id
 * @returns {AxiosPromise}
 */
export function editNoticeStatusApi (type, status, id) {
  return request({
    url: `setting/notification/set_status/${type}/${status}/${id}`,
    method: 'put'
  })
}

/**
 * 获取消息管理设置数据获取
 * @param id
 * @param type
 * @returns {AxiosPromise}
 */
export function getNotificationInfo (id, type) {
  return request({
    url: `setting/notification/info?id=${id}&type=${type}`,
    method: 'get'
  })
}

/**
 * 获取消息管理设置数据获取
 * @param data
 * @returns {AxiosPromise}
 */
export function getNotificationSave (data) {
  return request({
    url: 'setting/notification/save',
    method: 'post',
    data
  })
}

/**
 * 同步订阅消息
 * @param type
 * @returns {*}
 */
export function routineSyncTemplateApi (type) {
  return request({
    url: 'app/routine/syncSubscribe?type=' + type,
    method: 'GET'
  })
}

/**
 * @description 设置 身份管理 修改状态
 * @param {Object} param data {Object} 传值参数
 */
export function roleSetStatusApi(data) {
  return request({
    url: `setting/role/set_status/${data.id}/${data.status}`,
    method: 'PUT',
  });
}

/**
 * @description 设置 身份管理 ==新增 编辑
 * @param {Object} param data {Object} 传值参数
 */
export function roleCreatApi(data) {
  return request({
    url: `setting/role/${data.id}`,
    method: 'post',
    data,
  });
}

/**
 * @description 设置 身份管理 ==详情
 * @param {Number} param id {Number} 身份管理id
 */
export function roleInfoApi(id) {
  return request({
    url: `setting/role/${id}/edit`,
    method: 'get',
  });
}

/**
 * @description 设置 身份管理 列表
 * @param {Number} param id {Number} 物流公司id
 */
export function roleListApi(params) {
  return request({
    url: `setting/role`,
    method: 'GET',
    params,
  });
}

/**
 * @description 设置 身份管理 ==权限列表
 */
export function menusListApi() {
  return request({
    url: `setting/role/create`,
    method: 'get',
  });
}
